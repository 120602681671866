import { ASSETS } from '@/assets/index.ts';
import ImportModel from '@/compnents/import/impoortModel';
import type { SysPositionVO, SysTagCategoryVO, SysTagLibVO } from '@/pages/positionManagement/interface.ts';
import {
  type BaseData,
  MainSubStructureModel,
  NormalProgramme,
  type PaginationData,
  RenderByPermission,
  exportXlsx,
  extractValuesByKey,
  getWxUrl,
  request,
  requireSource,
  transformKeys,
} from '@/utils';
import { api } from '@/utils/api.ts';
import { DownloadOutlined, LinkOutlined, UploadOutlined } from '@ant-design/icons';
import { Modal, Popover, Space, Tag, message } from 'antd';
import dayjs from 'dayjs';
import { action, observable, runInAction, toJS } from 'mobx';
import ApprovalModel from '../clue/jdLibrary/checkModal/model.tsx';
import DetailModel from '../employer/employerManagement/employerDetail/model';
import type { SysTagLibSimpleVO } from '../employer/employerManagement/type.ts';
import AddModel from './components/add/model.tsx';
import { ShowToPartBrokerModel } from './components/add/showToPartBroker.tsx';
import {
  acceptNewbieDict,
  auditStatusDict,
  dataSourceDict,
  hasSocialSecurityDict,
  hasTryPostDict,
  hasTryUseDict,
  isKeepOfficeDict,
  jobTypeDict,
  requireSexDict,
  statusDict,
} from './enumeration.ts';
import InformationPopupModel from './informationPopupModel.tsx';
import RecommendedPositionModel from './recommendedPositionModel.tsx';

interface areaList {
  provinceId?: string;
  provinceName?: string;
  cityId?: string;
  cityName?: string;
  countyId?: string;
  countyName?: string;
}

export default class PositionManagementModel {
  constructor() {
    this.__init__();
  }
  private exportParams = {};
  public informationPopupModel = new InformationPopupModel(this);
  public recommendedPositionModel = new RecommendedPositionModel(this);
  public addModel = new AddModel(this);
  public showToPartBrokerModel = new ShowToPartBrokerModel(this);
  public normalProgramme: NormalProgramme;
  public mainSubStructureModel: MainSubStructureModel;
  @observable public auditPositionOpen = false;
  public categoryIdList: string[] = [];
  @observable public categoryList = {}; //自定义标签原数组
  public temporaryId: string | number; // 弹窗临时使用的id
  public importStore = new ImportModel(this); // 导入 model
  public employerDetailStore = new DetailModel(this);
  public approvalStore = new ApprovalModel(this); //审核弹窗

  public cities = [];
  @action
  public __init__() {
    this.initNormalProgramme();
    this.initMainSubStructureModel();
    this.getCategory();
    this.getLiveTypeList();
    this.getCityList();

    request<BaseData<SysTagLibSimpleVO[]>>({
      url: api.tag.getAllLibList,
      method: 'GET',
      params: {
        categoryType: 3,
      },
    }).then((res) => {
      const treeData = transformKeys(res.data, {
        name: 'label',
        id: 'value',
      });
      this.normalProgramme.filterItems.updateFilterItem([{ field: 'tagIds', treeData }]);
    });

    setTimeout(() => {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('positionId') && searchParams.get('isShowDetail') === 'true') {
        this.addModel.onOpen(2, searchParams.get('positionId'));
      }
    }, 500);
  }
  @action private changeLiveTypeId = (ids: string[]) => {
    const filterItem: any = this.normalProgramme.filterItems.getFilterItem('liveTypeId');
    const allIds = extractValuesByKey(toJS(filterItem?.treeData), 'value', 'children');
    const prevIsCheckedAll = filterItem?.value?.includes('0');
    const nowIsCheckedAll = ids?.includes('0');
    if (ids[ids?.length - 1] === '0' || (prevIsCheckedAll && !nowIsCheckedAll)) {
      this.normalProgramme.filterItems.updateFilterItem([
        {
          field: 'liveTypeId',
          value: prevIsCheckedAll && !nowIsCheckedAll ? [] : allIds,
        },
      ]);
    } else {
      if (ids.filter((item) => item !== '0')?.length === allIds.filter((item) => item !== '0')?.length) {
        this.normalProgramme.filterItems.updateFilterItem([{ field: 'liveTypeId', value: allIds }]);
        return;
      }
      this.normalProgramme.filterItems.updateFilterItem([
        { field: 'liveTypeId', value: ids.filter((item) => item !== '0') },
      ]);
    }
  };

  @action public getCityList = async () => {
    const cityResult = await request<BaseData<any>>({
      url: '/baseinfo/area/province/city/county/list',
      method: 'get',
      data: {},
    });
    const cityList = transformKeys(cityResult?.data || [], {
      name: 'label',
      id: 'value',
      childrenList: 'children',
    });
    this.cities = [...cityList];

    this.normalProgramme.filterItems.updateFilterItem([
      {
        field: 'cityIdList',
        treeData: [...cityList],
      },
    ]);
  };
  @action
  public getLiveTypeList = () => {
    request<PaginationData<any>>({
      url: '/pbb/platform/anon/system/liveType/list',
      method: 'post',
      data: { pageNum: 1, pageSize: 1000 },
    }).then((res) => {
      this.normalProgramme.filterItems.updateFilterItem([
        {
          field: 'liveTypeId',
          treeData: transformKeys(res.data, {
            name: 'label',
            id: 'value',
            childrenList: 'children',
          }),
        },
      ]);
    });
  };
  @action
  public getCategory = async () => {
    const req = await request<PaginationData<SysTagCategoryVO>>({
      method: 'POST',
      url: '/pbb/platform/sys/tag/category/list/page',
      data: {
        pageNum: 1,
        pageSize: 9999,
        showState: true,
        categoryType: 3,
      },
    });
    const categoryIdList: string[] = [];
    const categoryList = {};
    (req?.data?.list || [])?.forEach((item) => {
      categoryIdList.push(item.id);
      categoryList[item.id] = { categoryName: item.categoryName };
      this.getTagList(item.id);
      this.normalProgramme.filterItems.addItem([
        {
          type: 'select',
          mode: 'multiple',
          field: item.id,
          label: item.categoryName,
        },
      ]);
    });
    this.categoryList = categoryList || {};
    this.categoryIdList = categoryIdList || [];
  };
  @action
  public getTagList = async (id: string) => {
    const req = await request<PaginationData<SysTagLibVO>>({
      method: 'POST',
      url: '/pbb/platform/sys/tag/lib/list/page',
      data: {
        categoryIdList: [id],
        pageNum: 1,
        pageSize: 9999,
        categoryType: 3,
        showState: true,
      },
    });
    const data = (req?.data?.list || [])?.map((item) => {
      return {
        label: item.libName,
        value: item.id,
      };
    });
    const dict: any = { [id]: data };
    if (this.categoryList[id]) {
      this.categoryList[id] = { ...this.categoryList[id], list: data };
    }
    this.normalProgramme.filterItems.addDict(dict);
  };
  @action
  public onOrOffPosition = ({ id, status, callback }: { id: string; status: 1 | 2; callback?: () => void }) => {
    let title = '';
    if (status === 1) {
      title = '是否确认下线该职位，下线后将不会有主播报名';
    }
    if (status === 2) {
      title = '是否确认上线该职位';
    }
    Modal.confirm({
      type: 'warning',
      autoFocusButton: null,
      title,
      onOk: async () => {
        return await request({
          method: 'POST',
          url: '/pbb/platform/sys/position/onOrOffPositionById',
          data: {
            id,
            status,
          },
        }).then((req: any) => {
          message.success(req?.data || '操作成功');
          if (callback) {
            callback();
          }
          this.mainSubStructureModel.gridModel.onRefresh();
        });
      },
      onCancel: () => {},
    });
  };

  /**
   * 删除职位
   * @param id
   * @param callback
   */
  @action
  public onDelete = (id: string | number, callback?: () => void) => {
    Modal.confirm({
      type: 'warning',
      autoFocusButton: null,
      title: '确认删除该职位？',
      onOk: async () => {
        return await request({
          method: 'POST',
          url: '/pbb/platform/sys/position/deletePosition',
          data: {
            id,
          },
        }).then(() => {
          message.success('操作成功');
          if (callback) {
            callback();
          }
          this.mainSubStructureModel.gridModel.onRefresh();
        });
      },
      onCancel: () => {},
    });
  };
  public auditPositionCallback: () => void;
  @action
  public setAuditPositionOpen = () => {
    this.auditPositionOpen = !this.auditPositionOpen;
    if (!this.auditPositionOpen) {
      this.auditPositionCallback = undefined;
    }
  };

  public getParentId(list: any[], id: string) {
    for (const i in list) {
      if (list[i].value == id) {
        return [list[i]];
      }
      if (list[i].children) {
        const node = this.getParentId(list[i].children, id);
        if (node !== undefined) {
          return node.concat(list[i]);
        }
      }
    }
  }

  @action private getAreaList = (ids: string[]) => {
    const areaList: areaList[] = [];
    const value = ['provinceId', 'cityId', 'countyId'];
    const label = ['provinceName', 'cityName', 'countyName'];
    ids.map((item) => {
      const area = {
        provinceId: '0',
        provinceName: '',
        cityId: '',
        cityName: '',
        countyId: '0',
        countyName: '',
      };
      const result = this.getParentId(this.cities, item);
      if (result.length === 1 && result[0].children.length === 1) {
        area.countyId = result[0].children[0].value;
        area.countyName = result[0].children[0].label;
      }
      for (let i = result.length; i--; ) {
        area[value[result.length - i - 1]] = result[i].value;
        area[label[result.length - i - 1]] = result[i].label;
      }
      areaList.push(area);
    });

    return areaList;
  };

  @action private changeCityIds = (ids: string[]) => {
    const filterItem: any = this.normalProgramme.filterItems.getFilterItem('cityIdList');
    const allIds = extractValuesByKey(toJS(filterItem?.treeData), 'value', 'children');

    const prevIsCheckedAll = filterItem?.value?.includes('0');
    const nowIsCheckedAll = ids?.includes('0');

    if (ids[ids?.length - 1] === '0' || (prevIsCheckedAll && !nowIsCheckedAll)) {
      this.normalProgramme.filterItems.updateFilterItem([
        {
          field: 'cityIdList',
          value: prevIsCheckedAll && !nowIsCheckedAll ? [] : allIds,
        },
      ]);
    } else {
      if (ids.filter((item) => item !== '0')?.length === allIds.filter((item) => item !== '0')?.length) {
        this.normalProgramme.filterItems.updateFilterItem([{ field: 'cityIdList', value: allIds }]);
        return;
      }
      this.normalProgramme.filterItems.updateFilterItem([
        { field: 'cityIdList', value: ids.filter((item) => item !== '0') },
      ]);
    }
  };

  /**
   * 分享职位
   */
  public sharePosition = (id: string) => {
    getWxUrl({
      channel: 1,
      positionId: id,
    }).then((url) => {
      copyToClipboard(url);
      message.success('复制分享职位链接成功');
    });
  };

  public refresh = () => {
    this.mainSubStructureModel.gridModel.onRefresh();
  };
  public onRefresh = () => {
    this.mainSubStructureModel.gridModel.onRefresh();
  };

  @action
  public initNormalProgramme = () => {
    this.normalProgramme = new NormalProgramme({
      filterItems: [
        {
          type: 'input',
          field: 'employerInfo',
          label: '雇主信息',
          placeholder: '请输入用户昵称/手机号/公司名称/ID',
        },
        {
          type: 'input',
          field: 'name',
          label: '职位名称',
        },
        {
          type: 'select',
          field: 'jobType',
          label: '职位类型',
          data: [
            { label: '全职', value: '1' },
            { label: '长期兼职', value: '2' },
            { label: '临时兼职', value: '3' },
          ],
        },
        {
          type: 'treeSelect',
          field: 'cityIdList',
          label: '省市区',
          placeholder: '搜索选择',
          multiple: true,
          isParamList: true,
          treeCheckable: true,
          showCheckedStrategy: 'SHOW_PARENT',
          showSearch: true,
          allowClear: true,
          treeNodeFilterProp: 'label',
          onChange: (ids: string[]) => {
            this.changeCityIds(ids);
          },
        },
        {
          type: 'select',
          field: 'status',
          label: '职位状态',
          data: [
            { label: '待上线', value: '2' },
            { label: '已上线', value: '1' },
          ],
        },
        {
          type: 'select',
          field: 'auditStatus',
          label: '审核状态',
          data: [
            { label: '审核中', value: '0' },
            { label: '审核通过', value: '1' },
            { label: '审核失败', value: '2' },
          ],
        },
        {
          type: 'select',
          field: 'showToPartBroker',
          label: '展示给兼职经纪人',
          data: [
            { label: '是', value: '1' },
            { label: '否', value: '0' },
          ],
        },
        {
          type: 'treeSelect',
          field: 'liveTypeId',
          label: '直播类目',
          placeholder: '搜索选择',
          multiple: true,
          isParamList: true,
          treeCheckable: true,
          showCheckedStrategy: 'SHOW_PARENT',
          allowClear: true,
          showSearch: true,
          treeNodeFilterProp: 'label',
          onChange: (ids: string[]) => {
            this.changeLiveTypeId(ids);
          },
        },
        {
          type: 'inputNumberGroup',
          field: 'salary',
          label: '薪资范围',
        },
        {
          field: 'requireSex',
          label: '性别',
          type: 'select',
          data: [
            { label: '不限', value: '0' },
            { label: '男', value: '1' },
            { label: '女', value: '2' },
          ],
        },
        {
          type: 'select',
          field: 'acceptNewbie',
          label: '接受小白',
          data: [
            { label: '接受', value: '1' },
            { label: '不接受', value: '0' },
          ],
        },
        {
          type: 'select',
          field: 'isKeepOffice',
          label: '需要坐班',
          data: [
            { label: '需要', value: '1' },
            { label: '不需要', value: '0' },
          ],
        },
        {
          type: 'select',
          field: 'hasSocialSecurity',
          label: '购买社保',
          data: [
            { label: '不购买', value: '0' },
            { label: '入职购买', value: '1' },
            { label: '转正购买', value: '2' },
          ],
        },
        {
          type: 'select',
          field: 'hasTryPost',
          label: '有试岗期',
          data: [
            { label: '有', value: '1' },
            { label: '无', value: '0' },
          ],
        },
        {
          type: 'select',
          field: 'hasTryUse',
          label: '有试用期',
          data: [
            { label: '有', value: '1' },
            { label: '无', value: '0' },
          ],
        },
        {
          type: 'select',
          field: 'dataSource',
          label: '职位来源',
          data: [
            { label: 'PBB运营平台', value: '1' },
            { label: '小程序', value: '2' },
            { label: '导入', value: '3' },
          ],
        },
        {
          type: 'treeSelect',
          field: 'tagIds',
          label: '职位标签',
          treeCheckable: true,
          multiple: true,
          showSearch: true,
          treeNodeFilterProp: 'label',
        },
      ],

      handleSearch: () => this.mainSubStructureModel.onQuery(),
    });
  };
  @action
  public initMainSubStructureModel = () => {
    this.mainSubStructureModel = new MainSubStructureModel({
      buttons: [
        {
          text: '新增',
          permissionId: '2',
          handleClick: () => {
            this.addModel.onOpen(1);
          },
        },
        {
          text: '批量推荐',
          permissionId: '71',
          handleClick: () => {
            const ids = Array.from(this.mainSubStructureModel.gridModel.selectedIds);
            if (!ids.length) {
              message.warning('请选择需要推荐的职位！');
              return;
            }
            if (this.mainSubStructureModel.gridModel.selectRows.every((item) => item.status === 1)) {
              this.recommendedPositionModel.onOpen(ids as string[]);
              return;
            }
            message.warning('请选择已上线的职位进行推荐！');
          },
        },
        {
          text: '批量展示给兼职经纪人',
          permissionId: '2',
          handleClick: () => {
            const ids = Array.from(this.mainSubStructureModel.gridModel.selectedIds);
            if (!ids.length) {
              message.warning('请选择需要展示的职位！');
              return;
            }
            this.showToPartBrokerModel.onOpen(ids);
          },
        },
        {
          permissionId: '14',
          text: '批量审核',
          handleClick: () => {
            const ids = this.mainSubStructureModel.gridModel.selectRows
              .filter((item) => item.auditStatus !== undefined && item.auditStatus !== null)
              .map((item) => item.id);
            if (ids.length < 1) {
              message.warning('请选择有【审核状态】的职位!');
              return;
            }
            this.approvalStore.onOpenApproveModal(ids);
          },
        },
        {
          // permissionId: '28', TODO
          text: '导入',
          iconNode: <DownloadOutlined />,
          handleClick: () => {
            this.importStore.openImportModal('1');
          },
        },
        // {
        //   permissionId: '92',
        //   text: '导出',
        //   iconNode: <UploadOutlined />,
        //   handleClick: () => {
        //     exportXlsx('/pbb/platform/sys/position/export', this.exportParams, '职位管理');
        //   },
        // },
      ],
      pageId: new URLSearchParams(window.location.search)?.get('pageId'),
      grid: {
        columns: [
          {
            key: 'operate',
            name: '操作',
            minWidth: 260,
            formatter: ({ row }) => {
              const pageId = new URLSearchParams(window.location.search)?.get('pageId');
              const { status, auditStatus, id } = row;
              return (
                <Space>
                  <RenderByPermission permissionId={`${pageId}_21`}>
                    <a
                      onClick={async () => {
                        this.addModel.onOpen(2, row.id);
                      }}
                    >
                      详情
                    </a>
                  </RenderByPermission>
                  {[2].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_12`}>
                      <a
                        onClick={() => {
                          this.onOrOffPosition({ id: row.id, status: 2 });
                        }}
                      >
                        上线
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                  {[1].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_71`}>
                      <a
                        onClick={() => {
                          this.recommendedPositionModel.onOpen([row.id] as string[]);
                        }}
                      >
                        推荐
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                  {[1].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_87`}>
                      <a
                        type="link"
                        onClick={() => {
                          this.recommendedPositionModel.downloadPositionCard(row);
                        }}
                      >
                        下载职位卡片
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                  {[1].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_71`}>
                      <a
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.sharePosition(row.id);
                        }}
                      >
                        分享
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                  {[1].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_12`}>
                      <a
                        onClick={() => {
                          this.onOrOffPosition({ id: row.id, status: 1 });
                        }}
                      >
                        下线
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                  {[0, 1, 2].includes(auditStatus) ? (
                    <RenderByPermission permissionId={`${pageId}_14`}>
                      <a
                        onClick={() => {
                          this.approvalStore.onOpenApproveModal([id]);
                        }}
                      >
                        审核
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                  {[2].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_4`}>
                      <a
                        onClick={() => {
                          this.onDelete(row.id);
                        }}
                      >
                        删除
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                </Space>
              );
            },
          },
          {
            key: 'name',
            name: '职位名称',
            minWidth: 166,
          },
          {
            key: 'jobType',
            name: '职位类型',
            formatter: ({ row }) => {
              return jobTypeDict[row?.jobType] || '';
            },
          },
          {
            key: 'liveTypeName',
            name: '直播类目',
            minWidth: 166,
          },
          {
            key: 'companyAddress',
            name: '省市区',
            formatter: ({ row }) => {
              const { provinceName, cityName, countyName } = row.companyAddress;
              return `${provinceName || ''}${cityName || ''}${countyName || ''}`;
            },
          },
          {
            key: 'sysTagVOList',
            name: '职位标签',
            formatter: ({ row }) => {
              const dom = (row?.sysTagVOList || [])?.map((item, index) => {
                return <Tag key={`${index?.toString()}libName`}>{item.libName}</Tag>;
              });
              return (
                <Popover
                  placement="top"
                  title={'职位标签'}
                  content={<Space>{dom}</Space>}
                >
                  <Space>{dom}</Space>
                </Popover>
              );
            },
          },
          {
            key: 'status',
            name: '职位状态',
            formatter: ({ row }) => {
              return statusDict[row?.status] || '';
            },
          },
          {
            key: 'auditStatus',
            name: '审核状态',
            formatter: ({ row }) => {
              return auditStatusDict[row?.auditStatus] || '';
            },
          },
          {
            key: 'auditorInfo',
            name: '最新审核人',
            formatter: ({ row }) => {
              return (
                <div className="flex items-center cursor-pointer h-full gap-2">
                  <div className="text-12 leading-[18px]">
                    <p>昵称：{row?.auditorInfo?.name}</p>
                    <p>ID：{row?.auditorInfo?.id}</p>
                    <p>手机号：{row?.auditorInfo?.mobile}</p>
                  </div>
                </div>
              );
            },
          },
          {
            key: 'auditTime',
            name: '最新审核时间',
            minWidth: 166,
            formatter: ({ row }) => {
              return row.auditTime ? dayjs(row.auditTime).format('YYYY-MM-DD HH:mm:ss') : null;
            },
          },
          {
            key: 'showToPartBroker',
            name: '是否显示给兼职经纪人',
            formatter: ({ row }) => {
              return row.showToPartBroker === 1 ? '是' : row.showToPartBroker === 0 ? '否' : '';
            },
          },
          {
            key: 'salary',
            name: '薪资',
            minWidth: 188,
            formatter: ({ row }) => {
              if (row?.jobType?.toString() === '1' && row.salaryStart && row.salaryEnd) {
                return (
                  <div>
                    {row.salaryStart}-{row.salaryEnd}元*{row.salaryMonth}月
                  </div>
                );
              }
              if (['2', '3'].includes(row.jobType?.toString()) && row.salaryStart && row.salaryEnd) {
                return (
                  <div>
                    {row.salaryStart}-{row.salaryEnd}元/时
                  </div>
                );
              }
              return '';
            },
          },
          {
            key: 'recommendCount',
            name: '推荐人数',
            formatter: ({ row }) => {
              if (row.recommendCount) {
                return (
                  <a
                    onClick={() => {
                      this.informationPopupModel.onOpen(1, row.name, row.recommendCount, row.id, row.companyName);
                    }}
                  >
                    {row.recommendCount}人
                  </a>
                );
              }
              return '0人';
            },
          },
          {
            key: 'signUpCount',
            name: '报名人数',
            sortable: true,
            formatter: ({ row }) => {
              if (row.signUpCount) {
                return (
                  <a
                    onClick={() => {
                      this.informationPopupModel.onOpen(2, row.name, row.signUpCount, row.id, row.companyName);
                    }}
                  >
                    {row.signUpCount}人
                  </a>
                );
              }
              return '0人';
            },
          },
          {
            key: 'inviteCount',
            name: '邀约人数',
            sortable: true,
            formatter: ({ row }) => {
              if (row.inviteCount) {
                return (
                  <a
                    onClick={() => {
                      this.informationPopupModel.onOpen(3, row.name, row.inviteCount, row.id, row.companyName);
                    }}
                  >
                    {row.inviteCount}人
                  </a>
                );
              }
              return '0人';
            },
          },
          {
            key: 'inProcessCount',
            name: '状态进行中',
          },
          {
            key: 'validity',
            name: '有效期',
          },
          {
            key: 'employerInfo',
            name: '雇主信息',
            width: 290,
            formatter: ({ row }) => {
              const { employerInfo } = row;
              if (!employerInfo) {
                return '';
              }
              return (
                <div
                  className="flex items-center cursor-pointer h-full gap-2"
                  onClick={() => {
                    //弹出雇主信息资料
                    this.employerDetailStore.onOpenDetailModal(employerInfo.id, row.id);
                  }}
                >
                  <div className="relative">
                    <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                      <img
                        src={
                          employerInfo.avatar || 'https://xbygobs.bocaixingtu.cn/wx-employer/mine/avatar-default.png'
                        }
                        className="w-full h-full"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="text-12 leading-[18px]">
                    <div className="flex items-center">
                      {employerInfo.hasFee ? (
                        <>
                          <img
                            src={ASSETS.COMMON.PAY_STATUS}
                            className="w-13"
                            alt=""
                          />
                          <div className="text-[red]"> ({employerInfo.feeTimes})</div>
                        </>
                      ) : null}
                    </div>
                    <div className="flex items-center">
                      用户昵称：{employerInfo.userName}
                      <img
                        src={requireSource('/common/idcard.png')}
                        alt={''}
                        className={'w-4 h-4 ml-1'}
                      />
                    </div>
                    <div>ID：{employerInfo.id}</div>
                    <div>手机号：{employerInfo.mobile}</div>
                    <div>公司名称：{employerInfo.companyName}</div>
                  </div>
                </div>
              );
            },
          },
          {
            key: 'info',
            name: '工作地址',
            minWidth: 300,
            formatter: ({ row }) => {
              const { info } = row.companyAddress;
              return `${info || ''}`;
            },
          },
          {
            key: 'requireSex',
            name: '主播性别',
            formatter: ({ row }) => {
              return requireSexDict[row?.requireSex] || '';
            },
          },
          {
            key: 'acceptNewbie',
            name: '是否接受小白',
            formatter: ({ row }) => {
              return acceptNewbieDict[row?.acceptNewbie] || '';
            },
          },
          {
            key: 'isKeepOffice',
            name: '是否需要坐班',
            formatter: ({ row }) => {
              return isKeepOfficeDict[row?.isKeepOffice] || '';
            },
          },
          {
            key: 'hasSocialSecurity',
            name: '是否购买社保',
            formatter: ({ row }) => {
              return hasSocialSecurityDict[row?.hasSocialSecurity] || '';
            },
          },
          {
            key: 'hasTryPost',
            name: '是否有试岗期',
            formatter: ({ row }) => {
              return hasTryPostDict[row?.hasTryPost] || '';
            },
          },
          {
            key: 'hasTryUse',
            name: '是否有试用期',
            formatter: ({ row }) => {
              return hasTryUseDict[row?.hasTryUse] || '';
            },
          },
          {
            key: 'dataSource',
            name: '职位来源',
            minWidth: 166,
            formatter: ({ row }) => {
              return dataSourceDict[row?.dataSource] || '';
            },
          },
          {
            key: 'operatorInfo',
            name: '操作人',
            formatter: ({ row }) => {
              return (
                <div className="flex items-center cursor-pointer h-full gap-2">
                  <div className="text-12 leading-[18px]">
                    <p>昵称：{row?.operatorInfo?.name}</p>
                    <p>ID：{row?.operatorInfo?.id}</p>
                    <p>手机号：{row?.operatorInfo?.mobile}</p>
                  </div>
                </div>
              );
            },
          },
          {
            key: 'operatorTime',
            name: '操作时间',
            minWidth: 166,
            formatter: ({ row }) => {
              return row.operatorTime ? dayjs(row.operatorTime).format('YYYY-MM-DD HH:mm:ss') : null;
            },
          },
          {
            key: 'createTime',
            name: '创建时间',
            minWidth: 166,
            formatter: ({ row }) => {
              return row.createTime ? dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') : null;
            },
          },
          {
            key: 'brokerRecommend',
            name: '推荐理由',
            formatter: ({ row }) => {
              return <div className={'truncate w-full'}>{row?.brokerRecommend || '-'}</div>;
            },
          },
        ].map((v) => ({
          resizable: true,
          sortable: false,
          ...v,
        })),
        rows: [],
        primaryKeyField: 'id',
        sortByLocal: false,
        showCheckBox: true,
        showEmpty: true,
        rowHeight: 100,
      },
      hiddenSubTable: true,
      api: {
        onQuery: (params) => {
          const postParams = {
            ...params,
            filterParams: undefined,
          };
          const filterParams = this.normalProgramme.filterItems.params;
          if (filterParams?.salary) {
            const [salaryStart, salaryEnd] = filterParams.salary.split(',');
            filterParams.salaryStart = salaryStart;
            filterParams.salaryEnd = salaryEnd;
          }

          if (filterParams?.cityIdList) {
            // this.getAreaList(filterParams.cityIdList);
            // filterParams.areaList = this.areaList;
            filterParams.areaList = this.getAreaList(filterParams.cityIdList);
            filterParams.cityIdList = undefined;
          }

          let tagIds = [];
          Object.keys(filterParams).forEach((key) => {
            if (this.categoryIdList.includes(key)) {
              tagIds = [...tagIds, ...(filterParams[key] ? filterParams[key]?.split(',') || [] : [])];
              delete filterParams[key];
            }
          });

          if (filterParams?.tagIds) {
            filterParams.tagIds = filterParams.tagIds.split(',');
          }

          this.exportParams = {
            ...postParams,
            ...filterParams,
            tagIds: tagIds,
          };

          return request<PaginationData<Partial<SysPositionVO>>>({
            url: '/pbb/platform/sys/position/getPositionPageList',
            method: 'POST',
            data: {
              ...postParams,
              ...filterParams,
              tagIds: tagIds,
            },
          });
        },
      },
    });
    this.mainSubStructureModel.gridModel.onQuery();
  };
}
const copyToClipboard = async (text: string) => {
  // 尝试使用新的 Clipboard API
  if (navigator.clipboard && window.isSecureContext) {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text using Clipboard API: ', err);
    }
    return;
  }

  // 回退到使用 document.execCommand 方法
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, textarea.value.length);

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      console.log('Text copied to clipboard');
    } else {
      throw new Error('Failed to copy text using execCommand');
    }
  } catch (err) {
    console.error('Failed to copy text using execCommand: ', err);
  } finally {
    document.body.removeChild(textarea);
  }
};
